import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {GatsbyImage} from "gatsby-plugin-image";

import {slugify, GatsbyLink} from "../../utils";

import './BlogCard.scss'

export const BlogCard = ({ className, image, preview, slug, title, topics }) => {

    const classes = classNames(
        'blog-card',
        className
    );

    const renderTopics = () => {
        return <div className='topic-row'>
            {topics.map( ({name, slug}) => (
            <div key={`${slugify(name)}-topic-slug`} className='topic-slug'><GatsbyLink href={`/blog/${slug}`}>{name}</GatsbyLink></div>
            ))}
        </div>
    }

    return (
        <div className={classes}>
            <GatsbyLink href={`/blog/${slug}`}><GatsbyImage className='image' alt={title} image={image}/></GatsbyLink>
            {renderTopics()}
            <h3 className='title'><GatsbyLink href={`/blog/${slug}`}>{title}</GatsbyLink></h3>
            <p className='preview'>{preview}</p>
        </div>
    )
};

BlogCard.defaultProps = {
}

BlogCard.propTypes = {
    image: PropTypes.object.isRequired,
    preview: PropTypes.string.isRequired,
    title:  PropTypes.string.isRequired,
    topics:  PropTypes.array.isRequired,
    className: PropTypes.string
};

export default BlogCard;
