import React from 'react'
import {graphql} from "gatsby";
import PropTypes from 'prop-types';

import  Layout from '../../components/layout'
import  Seo from '../../components/seo'

import './topics-template.scss';
import {BlogCard} from "../../components/composites/BlogCard/BlogCard";
import {getImage} from "gatsby-plugin-image";
import {slugify} from "../../components/utils";

const TopicsTemplate = ({ data, location, pageContext }) => {
    const {
        allContentfulBlogPost,
    } = data

    const posts = allContentfulBlogPost.nodes;
    const topicName = pageContext.name;
    const topicDescription = pageContext.description;

    return (
        <Layout className='topics-template'>
            <Seo title={topicName}/>
            <div className='topic-container'>
                <div className='topic'>
                    <h1 className='topic-title'>{topicName}</h1>
                    <p className='topic-description'>{topicDescription}</p>
                </div>

                <div className='blog-grid'>
                    {posts.map( ({description, heroImage, slug, title, topics}) => (
                        <BlogCard key={`${slugify(title)}-blog-card`} title={title} preview={description.description} image={getImage(heroImage.gatsbyImageData)} slug={slug} topics={topics} />
                    ))}
                </div>
            </div>
        </Layout>
    )
}

TopicsTemplate.propTypes = {
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired
}

export default TopicsTemplate

export const topicQuery = graphql`
  query TopicBySlug($slug: String!) {
    allContentfulBlogPost(
      filter: {topics: {elemMatch: {slug: {eq: $slug}}}}
      sort: { fields: [publishDate], order: DESC }
    ) {
        nodes {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          description {
            description
          }
          body {
            childMarkdownRemark {
                html
          }
        }
        heroImage {
          gatsbyImageData
        }
        topics {
          name
          slug
        }
      }
    }
  }
`
